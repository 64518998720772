import React from "react";
import { Navbar } from "../../tailwind";
import { Footer } from "../../tailwind/Footer";

export default () => (
  <>
      <Navbar />
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
          <div className="bg-white rounded border shadow mt-10 p-6">
              <h1 className="text-2xl text-gray-800 font-semibold py-1">Required Car Insurance Application Information</h1>
              <hr className="py-1"/>
              <p className="text-gray-700 text-sm">Car insurance companies are making it easier than ever to
              shop for coverage, but there is still a certain amount of information that you must provide in order to get an
              accurate quote. Before you contact an insurance agency, have the following information readily available to smooth
              the process.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Names and driver's license information of vehicle owners and drivers</h2><p className="text-gray-700 text-sm">The first thing you
              are typically asked to provide is your name and driver's license information, plus the names and license information
              of everyone on the car title and any additional drivers you want listed on the policy. The insurance company will
              verify that all are legally licensed, and they will also pull data concerning driving records and any violations.
              Some insurance agencies will even require you to provide this information not just for car owners and drivers, but
              for every licensed driver living in your household, making the assumption that they are also potential operators of
              the car. If any drivers are students, be sure to include positive information not available by pulling license date,
              such as safe driving courses completed and good grades while in school, as these can often lead to lower
              premiums.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Make, model and other car details</h2><p className="text-gray-700 text-sm">The type of car you drive, its age, even its color, are
              all factors that companies can use to determine how much your policy will cost. You will be asked to provide the VIN
              (Vehicle Identification Number) which can be located on the title or the registration paperwork. This allows the
              insurance company to verify legal ownership and other vehicle details.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Liens on the vehicle</h2><p className="text-gray-700 text-sm">The
              insurance company will require you to declare whether there are any lien holders for the vehicle. While basic
              liability insurance is a legal requirement for operating a motor vehicle practically everywhere, lien holders
              typically require you to obtain additional collision and comprehensive insurance to protect their investment. If you
              are making payments on your car to a finance company, car dealership or even a title loan company, you will likely
              need this increased insurance coverage.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Physical address where the car will reside</h2><p className="text-gray-700 text-sm">There can be a big
              difference in the cost of insurance based solely on where the car is driven and parked. Congested areas with a lot
              of traffic, neighborhoods prone to auto break-ins or thefts, even residences where garages are unavailable and cars
              must be parked on the street can impact rates. Conversely, you may save money if you live in a quiet area with
              little crime and limited traffic.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Average number of miles driven</h2><p className="text-gray-700 text-sm">The more miles you drive your car,
              the more likely you are, statistically speaking, to be involved in an accident. You will be asked whether the car is
              used as transportation to and from work, and how many miles annually that you are likely to be on the road. If you
              vehicle is used in a business capacity and not just driven to work, you will need a different level of insurance, so
              be certain to make this point clear.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Anti-theft and other safety devices</h2><p className="text-gray-700 text-sm">Most newer model cars come
              with standard safety features such as anti-lock brakes, self-locking doors and air bags, but additional items like
              GPS tracking, onboard assistance calling or ignition kill capability may decrease your premiums.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Credit and
              insurance scores</h2><p className="text-gray-700 text-sm">While you may not have available, or even know, your credit and insurance scores, the
              insurance company will likely disclose that these scores will be used when making a decision about your rates. They
              will ask for information from you to obtain these scores such as social security number, date of birth, full and
              maiden name, current and prior addresses and possibly more. Good credit and insurance scores can save you a sizeable
              amount of money. Lower scores make insurance companies believe that you may not take good care of your assets and
              they will price your premium accordingly whether it is true or not.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Homeowner and employment status</h2>
              <p className="text-gray-700 text-sm">Insurance companies consider homeowners to be more stable than those who rent, thus less risky to insure.
                  Likewise for people who have a long employment history with the same company, or at least no gaps in employment.
                  Homeowners may receive additional discounts for bundling homeowner's insurance with auto policies. Multiple cars
                  on one policy also leads to lower premiums per car.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Current insurance coverage</h2><p className="text-gray-700 text-sm">If your car is
              already insured, the level of coverage that you are carrying gives other insurance companies the ability to give
              quotes apples to apples, unless you are planning to add or remove certain coverage details. Some companies also
              penalize gaps in coverage if it appears that you were negligent in letting the coverage lapse.</p><p className="text-gray-700 text-sm">Providing
              accurate information to your insurance company means you get the coverage that is appropriate for your situation at
              the lowest possible cost. Make sure you have the right level of coverage in case you ever need to use it.</p>
          </div>
      </div>
      <Footer/>
  </>
)