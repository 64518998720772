import React from "react";
import rateinsiderLogo from "../images/logo.png";

export const Footer = () => (
  <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
    <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-3">

      <div className="space-y-2 sm:col-span-1">
        <a
          href="/"
          aria-label="Go home"
          title="Rateinsider"
          className="inline-flex items-center"
        >
          <img src={rateinsiderLogo} alt="Brand logo" className="w-auto md:w-full h-16"/>
        </a>
        <div className="lg:max-w-sm">
          <p className="text-sm text-gray-800">
            The advertised discounts or advertised rates are not available from all of our insurance partners. The
            lowest advertised rate is monthly premium for semiannually paid insurance policy on 1981 Buick Century Sedan
            that’s driven less than 4,000 miles per year by 62 or older male with good driving record and alumni
            membership.
          </p>
        </div>
      </div>

      <div className="space-y-2 text-sm">
        <p className="text-base font-bold tracking-wide text-gray-900">
          Contacts
        </p>
        <div className="flex-col">
          <a
            href="tel:+1 647-478-9598"
            aria-label="Call (844) 334-1515"
            title="Call (844) 334-1515"
            className="text-gray-900"
          >
            (844) 334-1515
          </a>
        </div>
        <div className="flex-col">
          <a
            href="mailto:sales@bluefinenterprises.com"
            aria-label="sales@bluefinenterprises.com"
            title="sales@bluefinenterprises.com"
            className="text-gray-900"
          >
            sales@bluefinenterprises.com
          </a>
        </div>
        <div className="flex-col">
          <span
            aria-label="2050 River Road West Wasaga Beach, Ontario, L9Z 2W1, Canada"
            title="2050 River Road West Wasaga Beach, Ontario, L9Z 2W1, Canada"
            className="text-gray-900"
          >
            2050 River Road West Wasaga Beach, Ontario, L9Z 2W1, Canada
          </span>
        </div>
      </div>
      <div>
          <span className="text-base font-bold tracking-wide text-gray-900">
            Learn More
          </span>
        <ul className="mt-1 flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0">
          <li>
            <a
              href="/pages/car-insurance-discounts"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Auto Insurance Discounts
            </a>
          </li>
          <li>
            <a
              href="/pages/car-insurance-application"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Car Insurance Application Information
            </a>
          </li>
          <li>
            <a
              href="/pages/pip-insurance"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              What is PIP Insurance?
            </a>
          </li>
          <li>
            <a
              href="/pages/sr-22-fr-44-insurance"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              SR22 and FR-44 Insurance
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
      <p className="text-sm text-gray-600">
        Copyright &copy; 2015 - {new Date().getFullYear()} RateInsider.com. All Rights Reserved.
      </p>
      <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
        <li>
          <a
            href="https://rocketcloud.com"
            target="_blank"
            className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            Cold Leads
          </a>
        </li>
        <li>
          <a
            href="/pages/privacy"
            className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            href="/pages/terms"
            className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            Terms of Use
          </a>
        </li>
        <li>
          <a
            href="/pages/contact"
            className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            Contact Information
          </a>
        </li>
      </ul>
    </div>
  </div>
);